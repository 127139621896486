.fade_in {
    animation: fadeIn 1.5s;
}
.fade_out {
    animation: fadeOut 1.5s;
}
.arrow_animate{
    animation: arrow 1s infinite;
}
.inview_animate{
     position: absolute;
     background-color: #D8CFC2;
     opacity: 50%;
     height: 100%;
     width: 100%;
     z-index: 10;
     opacity: 0;
    animation: inview 1s;
  
}
.inview_animate_left{
    position: absolute;
    background-color: #D8CFC2;
    opacity: 50%;
    height: 100%;
    width: 100%;
    z-index: 10;
    opacity: 0;
   animation: inview_left 1s;
}



@keyframes fadeIn {
    0% { opacity: 0;
      
        }
    100% { 
            opacity: 1;
          
             }
}
@keyframes fadeOut {
    0% { opacity: 0;
       
    }
    100% { opacity: 0.7;
         }
}

@keyframes arrow {
    0%, 100% {
      transform: translateX(-5%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateX(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
  
@keyframes inview {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(100%);
        opacity: 1;
    }
  }
  @keyframes inview_left {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(-100%);
        opacity: 1;
    }
  }
  
