@tailwind base;
@tailwind components;
@tailwind utilities;

.map-container {
    height: 40vh;
    width: 100vw;
    }

    .mapboxgl-popup-close-button {
         width:15px;
         height:15px;
         object-fit: cover;
         font-size: 25px;
         color: #414142;
         margin: 5px;
    }

.swiper-button-next::after {
     color: #ffff !important;
     opacity: 0.7;   
     content: url(/public/assets/icon/right.svg) !important;  
     margin-right:100px;
  
}
.swiper-button-prev::after {
     color: #ffff !important;
     opacity: 0.7;
     content: url(/public/assets/icon/left.svg) !important;  
     margin-left: 100px;
}




@media only screen and (max-width: 800px) {
     .swiper-button-next::after, .swiper-button-prev::after {
          display: none;
     }
   }

@media only screen and (max-width: 1200px) {
     
.swiper-button-next::after {
     margin-right:10px;
  
}
.swiper-button-prev::after {
     margin-left: 10px;
}

   }